import { DataGrid } from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: "lightblue",
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    backgroundColor: "#fff"
  }
}));

export default StyledDataGrid;
