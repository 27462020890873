import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import useFetch from 'use-http';
import { GridColDef } from '@mui/x-data-grid';
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { Add, Delete } from "@mui/icons-material";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { useState, useEffect, useCallback } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import DateTimePicker from '@mui/lab/DateTimePicker';
import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import Autocomplete from '@mui/material/Autocomplete';
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import baseUrl from '../helpers/urls';
import ExportToolbar from "../components/ExportToolbar";
import StyledDataGrid from "../components/StyledDataGrid";

export const scheduleColumns: GridColDef[] = [
  { field: 'company_name', editable: false, headerName: 'Customer Name', width: 150 },
  { field: 'type', headerName: 'Event', width: 100 },
  { field: 'start_time', editable: true, type: 'dateTime', headerName: 'Start Time', width: 200, valueFormatter: (params) => params.value ? format(new Date(params.value.toString()), 'PPpp') : null },
  { field: 'end_time', editable: true, type: 'dateTime', headerName: 'End Time', width: 200, valueFormatter: (params) => params.value ? format(new Date(params.value.toString()), 'PPpp') : null },
];

export default function Schedules() {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [schedules, setSchedules] = useState<any>([]);
  const [selectedSchedules, setSelectedSchedules] = useState<any>([]);
  const { response: customerResponse, get: getCustomers } = useFetch(`${baseUrl}/customers`);
  const { response: jobResponse, get: getJobs } = useFetch(`${baseUrl}/jobs`);
  const { get, response, loading, error, post } = useFetch(`${baseUrl}/schedules`);
  const { del, put } = useFetch(`${baseUrl}/schedules`);

  const [isDeleting, setIsDeleting] = useState(false);
  const [totalToDelete, setTotalToDelete] = useState(0);
  const [currentlyDeletingIndex, setCurrentlyDeletingIndex] = useState(0);
  const [customerAutocompleteList, setCustomerAutoCompleteList] = useState<any>([]);
  const [jobAutocompleteList, setJobAutoCompleteList] = useState<any>([]);
  const [editCellSnackbar, setEditCellSnackbar] = useState<any>(null);

  useEffect(() => { }, []) // componentDidMount

  async function loadInitialCustomers() {
    const initialCustomers = await getCustomers();
    if (customerResponse.ok) setCustomerAutoCompleteList(
      initialCustomers.customers.map((customer: any) => ({
        label: customer.company_name,
        value: customer.id
      }))
    );
  }

  async function loadInitialJobs() {
    const initialJobs = await getJobs();
    if (jobResponse.ok) setJobAutoCompleteList(
      initialJobs.jobs.map((job: any) => ({
        label: `${new Date(job.start_date).toDateString()} - ${new Date(job.end_date).toDateString()}`,
        value: job.id,
        customerId: job.customer_id,
      }))
    );
  }

  const [formData, setFormData] = useState({
    email: '',
    startTime: new Date(),
    endTime: addHours(new Date(), 1),
    customerId: '',
    jobId: '',
    type: '',
  });

  function updateForm(value: any, fieldName: string) {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  useEffect(() => {
    loadInitialSchedules();
    loadInitialCustomers();
    loadInitialJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // componentDidMount

  async function loadInitialSchedules() {
    const initialSchedules = await get();
    if (response.ok) setSchedules(initialSchedules.schedules);
  }

  async function createSchedule() {
    const scheduleData = await post(
      {
        schedule:
        {
          customer_id: formData.customerId,
          start_time: formData.startTime,
          end_time: formData.endTime,
          job_id: formData.jobId,
          type: formData.type,
        }
      }
    );
    if (response.ok) {
      setSchedules(scheduleData.schedules);
      setAddModalOpen(false);
    }
  }

  const handleCellEditCommit = useCallback(
    async (params) => {
      try {
        // Make the HTTP request to save in the backend
        const { schedule } = await put(`${params.id}`, {
          schedule: {
            [params.field]: params.value,
          }
        });
        setEditCellSnackbar({ children: 'Invoice successfully saved', severity: 'success' });
        setSchedules((prev: any) =>
          prev.map((row: any) => (row.id === params.id ? { ...row, ...schedule[0] } : row)),
        );
      } catch (error) {
        setEditCellSnackbar({ children: 'Error while saving user', severity: 'error' });
        // Restore the row in case of error
        setSchedules((prev: any) => [...prev]);
      }
    },
    [put],
  );

  async function deleteSchedules() {
    setIsDeleting(true);
    const oldSelectedSchedules = selectedSchedules;
    setCurrentlyDeletingIndex(1);
    setTotalToDelete(oldSelectedSchedules.length);
    let newSchedules = schedules;
    oldSelectedSchedules.map(async (scheduleId: any, index: number) => {
      const scheduleData = await del(`${scheduleId}`);
      if (scheduleData.deleteCount === 1) {
        newSchedules = newSchedules.filter(({ id }: { id: number }) => id !== scheduleId);
        setCurrentlyDeletingIndex(index++);
      }
      if (index === oldSelectedSchedules.length) {
        setIsDeleting(false);
        setSchedules(newSchedules);
        setSelectedSchedules([]);
      }
    });
  }

  return (
    <>
      {!!editCellSnackbar && (
        <Snackbar open onClose={() => setEditCellSnackbar(false)} autoHideDuration={6000}>
          <Alert {...editCellSnackbar} onClose={() => setEditCellSnackbar(false)} />
        </Snackbar>
      )}
      <Typography variant="h5">Schedules</Typography>
      {error && <Typography>Error loading schedules</Typography>}
      {loading && <CircularProgress />}
      {isDeleting &&
        <Alert severity="info">
          <AlertTitle>Deleting Schedules</AlertTitle>
          {currentlyDeletingIndex} / {totalToDelete}
        </Alert>}
      {!loading &&
        <StyledDataGrid
          showColumnRightBorder
          showCellRightBorder
          disableSelectionOnClick
          onCellEditCommit={handleCellEditCommit}
          checkboxSelection
          style={{ height: 'calc(100vh - 220px)' }}
          rows={schedules}
          columns={scheduleColumns}
          selectionModel={selectedSchedules}
          onSelectionModelChange={(selected) => setSelectedSchedules(selected)}
          components={{
            Toolbar: ExportToolbar,
          }}
        />}
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 32, right: 32 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          onClick={() => setAddModalOpen(true)}
          icon={<Add />}
          tooltipTitle="Add Schedule"
        />
        <SpeedDialAction
          onClick={() => deleteSchedules()}
          icon={<Delete />}
          tooltipTitle="Delete Selected Schedules"
        />
      </SpeedDial>
      <Dialog open={addModalOpen} onClose={() => setAddModalOpen(false)}>
        <DialogTitle>Add Schedule</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Autocomplete
              sx={{ mt: 2 }}
              disablePortal
              options={customerAutocompleteList}
              onChange={(_e, newValue: any) => updateForm(newValue.value, 'customerId')}
              renderInput={(params) => <TextField {...params} fullWidth label="Customer Reference" />}
            />
            <Autocomplete
              disablePortal
              options={jobAutocompleteList}
              filterOptions={(options) => {
                return options.filter((option: any) =>
                  option.customerId === formData.customerId
                )
              }}
              onChange={(_e, newValue: any) => updateForm(newValue.value, 'jobId')}
              renderInput={(params) => <TextField {...params} fullWidth label="Job Start/End Date" />}
            />
            <Autocomplete
              disablePortal
              options={["quote", "work", "pickup", "drop off"]}
              onChange={(_e, newValue: any) => updateForm(newValue, 'type')}
              renderInput={(params) => <TextField {...params} fullWidth label="Event" />}
              value={formData.type}
            />
          </Stack>
          <DateTimePicker
            renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
            label="Start Time"
            onChange={(e) => updateForm(e, "startTime")}
            value={formData.startTime}
          />
          <DateTimePicker
            renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
            label="End Time"
            onChange={(e) => updateForm(e, "endTime")}
            value={formData.endTime}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddModalOpen(false)}>Cancel</Button>
          <Button onClick={createSchedule}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
