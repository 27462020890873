import { AttachMoney } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, TextField, InputAdornment, DialogActions, Button } from "@mui/material"
import { useState } from "react";
import useFetch from "use-http";
import baseUrl from "../helpers/urls";

type AddMaterialCostModalProps = {
  open: boolean;
  onClose: () => void;
  jobId: string;
}

export default function AddMaterialCostModal({ open, onClose, jobId }: AddMaterialCostModalProps) {

  const { response, post } = useFetch(`${baseUrl}/material_costs`);

  const [formData, setFormData] = useState({
    cost: 0,
    supplier: "",
    description: "",
  });

  function updateForm(value: any, fieldName: string) {
    return setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  async function createMaterialCost() {
    await post(
      {
        materialCost: {
          supplier: formData.supplier,
          description: formData.description,
          cost_in_cents: formData.cost * 100,
          job_id: jobId,
        }
      }
    );
    if (response.ok) {
      onClose();
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Invoice</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Description"
          fullWidth
          variant="standard"
          value={formData.description}
          onChange={(e) => updateForm(e.target.value, 'description')}
        />
        <TextField
          margin="dense"
          label="Supplier"
          fullWidth
          variant="standard"
          value={formData.supplier}
          onChange={(e) => updateForm(e.target.value, 'supplier')}
        />
        <TextField
          margin="dense"
          label="Cost"
          fullWidth
          variant="standard"
          value={formData.cost}
          type="number"
          onChange={(e) => updateForm(e.target.value, 'cost')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoney />
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={createMaterialCost}>Create</Button>
      </DialogActions>
    </Dialog>
  )
}