import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import useFetch from 'use-http';
import useUser from "../hooks/useUser";
import DateTimePicker from '@mui/lab/DateTimePicker';
import InputAdornment from "@mui/material/InputAdornment";
import { AttachMoney, Close } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Collapse from '@mui/material/Collapse';
import Paper from "@mui/material/Paper";
import GoogleMapsAutocomplete from "../components/GoogleMapsAutocomplete";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import baseUrl from '../helpers/urls';
import { invoiceStatuses } from "./Invoices";

type WizardProps = {
  onClose: (response: any) => void;
}

export default function Wizard({ onClose }: WizardProps) {
  const [user] = useUser();
  const { post, loading, error, response } = useFetch(`${baseUrl}/${user.company.id}/wizards`);
  const { response: customerResponse, get: getCustomers } = useFetch(`${baseUrl}/customers`);
  const { get: getAdditionalFieldSchema, response: additionalFieldSchemaResponse } = useFetch(`${baseUrl}/jobs/field`);
  const { get: getJobStatuses, response: jobStatusesResponse } = useFetch(`${baseUrl}/job_statuses`)

  const [additionalFields, setAdditionalFields] = useState<GridColDef[]>([]);

  const [createCustomer, setCreateCustomer] = useState(false);
  const [createInvoice, setCreateInvoice] = useState(false);

  const [customerAutocompleteList, setCustomerAutoCompleteList] = useState<any>([]);
  const [statuses, setStatuses] = useState<any>([]);

  async function loadAdditionalFieldSchema() {
    const additionalFieldResponse = await getAdditionalFieldSchema();
    if (additionalFieldSchemaResponse.ok) {
      setAdditionalFields(
        additionalFieldResponse.jobSchema.map(({ label, type }: { label: string, type: string }) => ({
          field: label, headerName: label, type: type.charAt(0) === "[" ? "singleSelect" : type, editable: true, width: 200, ...(type.charAt(0) === "[" ? { valueOptions: JSON.parse(type).reduce((prev: string[], { label }: { label: string }) => [...prev, label], []) } : {})
        }))
      )
    };
  }

  useEffect(() => {
    loadInitialCustomers();
    loadAdditionalFieldSchema();
    loadInitialJobStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadInitialJobStatuses() {
    const statusData = await getJobStatuses();
    if (jobStatusesResponse.ok) setStatuses(statusData.jobStatuses);
  }

  async function loadInitialCustomers() {
    const initialCustomers = await getCustomers();
    if (customerResponse.ok) setCustomerAutoCompleteList(
      initialCustomers.customers.map((customer: any) => ({
        label: customer.company_name,
        value: customer.id
      }))
    );
  }

  const [formData, setFormData] = useState({
    customer: {
      email: '',
      first_name: '',
      last_name: '',
      company_name: '',
      address: '',
      customer_id: '',
      phone_number: '',
    },
    job: {
      start_date: new Date(),
      end_date: new Date(),
      customer_id: "",
      address: "",
      status_id: "",
    },
    invoice: {
      due_date: new Date(),
      customer_id: '',
      job_id: '',
      cost: 0,
      status: '',
    },
  });

  const [scheduleData, setScheduleData] = useState<any>([])

  function updateFormField(value: any, name: string, formDataName: 'customer' | 'job' | 'invoice') {
    setFormData({
      ...formData,
      [formDataName]: {
        ...formData[formDataName],
        [name]: value
      },
    })
  }

  const [additionalFormData, setAdditionalFormData] = useState<any>({});

  function updateAdditionalForm(value: any, fieldName: string) {
    console.log({
      ...additionalFormData,
      [fieldName]: value,
    })
    setAdditionalFormData({
      ...additionalFormData,
      [fieldName]: value,
    })
  }

  function updateScheduleData(value: any, index: number, name: string) {
    setScheduleData([
      ...scheduleData.filter((schedule: any, idx: number) => idx !== index),
      {
        ...scheduleData[index],
        [name]: value,
      }
    ])
  }

  async function createWithWizard() {
    const wizardResponse = await post({
      ...formData,
      createCustomer,
      createInvoice,
      schedules: scheduleData,
      additionFields: additionalFormData
    });
    if (response.ok) onClose(wizardResponse);
  }

  if (loading) {
    return (
      <Container maxWidth="sm">
        <CircularProgress color="secondary" />
      </Container>
    )
  }

  if (!!error) {
    return (
      <Container maxWidth="sm">
        <Typography>{error}</Typography>
      </Container>
    )
  }

  return (
    <Container maxWidth="sm">
      <IconButton size="large" style={{ position: 'absolute', top: 4, right: 4 }} onClick={() => onClose({})}>
        <Close />
      </IconButton>
      <Typography variant="h4" align="center">Job Wizard</Typography>
      <Paper sx={{ p: 2, m: 2 }}>
        <Typography variant="h6">Customer</Typography>
        <FormControlLabel
          control={
            <Checkbox
              value={createCustomer}
              onChange={(_e, checked) => setCreateCustomer(checked)}
            />}
          label='Create new customer?'
        />
        <Stack spacing={2}>
          <Collapse orientation="vertical" in={createCustomer} mountOnEnter unmountOnExit>
            <TextField
              autoFocus
              margin="dense"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              name="email"
              onChange={(e) => updateFormField(e.target.value, 'email', 'customer')}
            />
            <TextField
              margin="dense"
              label="Customer Name"
              fullWidth
              variant="standard"
              name="company_name"
              onChange={(e) => updateFormField(e.target.value, 'company_name', 'customer')}
            />
            <TextField
              margin="dense"
              label="First Name"
              fullWidth
              variant="standard"
              name="first_name"
              onChange={(e) => updateFormField(e.target.value, 'first_name', 'customer')}
            />
            <TextField
              margin="dense"
              label="Last Name"
              fullWidth
              variant="standard"
              name="last_name"
              onChange={(e) => updateFormField(e.target.value, 'last_name', 'customer')}
            />
            <GoogleMapsAutocomplete
              onChange={(e) => updateFormField(e?.description || '', 'address', 'customer')}
            />
            <TextField
              margin="dense"
              label="Phone Number"
              fullWidth
              variant="standard"
              name="phoneNumber"
              onChange={(e) => updateFormField(e.target.value, 'phone_number', 'customer')}
            />
          </Collapse>
        </Stack>
        <Stack>
          <Collapse orientation="vertical" in={!createCustomer} mountOnEnter unmountOnExit>
            <Autocomplete
              disablePortal
              options={customerAutocompleteList}
              onChange={(_e, newValue: any) => updateFormField(newValue.value, 'customer_id', 'customer')}
              renderInput={(params) => <TextField {...params} fullWidth label="Customer Reference" />}
            />
          </Collapse>
        </Stack>
      </Paper>
      <Paper sx={{ p: 2, m: 2 }}>
        <Typography variant="h6">Job</Typography>
        <Stack>
          <GoogleMapsAutocomplete
            onChange={(e) => updateFormField(e?.description || '', 'address', "job")}
          />
          <DateTimePicker
            renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
            label="Start Date"
            onChange={(date) => updateFormField(date, "start_date", "job")}
            value={formData.job.start_date}
          />
          <DateTimePicker
            renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
            label="End Date"
            onChange={(date) => updateFormField(date, "end_date", "job")}
            value={formData.job.end_date}
          />
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel id="status-select">Status</InputLabel>
            <Select
              labelId="status-select"
              label="Status"
              onChange={(e) => updateFormField(e.target.value as string, "status_id", "job")}
            >
              {statuses.map((option: any) => <MenuItem value={option.id}>{option.label}</MenuItem>) || []}
            </Select>
          </FormControl>
          {additionalFields.map(({ type = "", field, valueOptions = [] }) => {
            switch (type) {
              case "string":
                return <TextField
                  sx={{ mt: 2 }}
                  margin="dense"
                  label={field}
                  fullWidth
                  variant="standard"
                  name={field}
                  onChange={(e) => updateAdditionalForm(e.target.value, field)}
                  value={additionalFormData[field] || ""}
                />;
              case "number":
                return <TextField
                  sx={{ mt: 2 }}
                  margin="dense"
                  label={field}
                  fullWidth
                  type="number"
                  variant="standard"
                  name={field}
                  onChange={(e) => updateAdditionalForm(e.target.value, field)}
                  value={additionalFormData[field] || 0}
                />;
              case "date":
                return <DateTimePicker
                  renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
                  label={field}
                  onChange={(date) => updateAdditionalForm(date, field)}
                  value={additionalFormData[field] || new Date()}
                />;
              case "dateTime":
                return <DateTimePicker
                  renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
                  label={field}
                  onChange={(date) => updateAdditionalForm(date, field)}
                  value={additionalFormData[field] || new Date()}
                />;
              case "boolean":
                return <FormControlLabel
                  sx={{ mt: 2 }}
                  control={
                    <Checkbox
                      value={additionalFormData[field] || false}
                      onChange={(_e, checked) => updateAdditionalForm(checked, field)}
                    />}
                  label={field}
                />
              case "singleSelect":
                const options = Array.isArray(valueOptions) ? valueOptions : [];
                return <Select
                  sx={{ mt: 2 }}
                  fullWidth
                  label={field}
                  onChange={(e) => updateAdditionalForm(e.target.value as string, field)}
                >
                  {options.map((option) => <MenuItem value={option.toString()}>{option}</MenuItem>)}
                </Select>;
              default:
                return <></>;
            }
          })}
        </Stack>
      </Paper>
      <Paper sx={{ p: 2, m: 2 }}>
        <Stack>
          <Typography variant="h6">Invoice</Typography>
          <FormControlLabel
            control={
              <Checkbox
                value={createInvoice}
                onChange={(_e, checked) => setCreateInvoice(checked)}
              />}
            label='Create invoice?'
          />
          <Collapse orientation="vertical" in={createInvoice} mountOnEnter unmountOnExit>
            <DateTimePicker
              renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
              label="Due Date"
              onChange={(date) => updateFormField(date, 'due_date', 'invoice')}
              value={formData.invoice.due_date}
            />
            <TextField
              margin="dense"
              label="Cost"
              fullWidth
              variant="standard"
              value={formData.invoice.cost}
              type="number"
              onChange={(e) => updateFormField(parseFloat(e.target.value), 'cost', 'invoice')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoney />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel id="invoice-status-select">Status</InputLabel>
              <Select
                labelId="invoice-status-select"
                label="Status"
                value={formData.invoice.status}
                onChange={(e) => updateFormField(e.target.value as string, 'status', 'invoice')}
              >
                {invoiceStatuses.map((status): JSX.Element => <MenuItem value={status}>{status}</MenuItem>)}
              </Select>
            </FormControl>
          </Collapse>
        </Stack>
      </Paper>
      <Paper sx={{ p: 2, m: 2 }}>
        <Stack>
          <Typography variant="h6">Scheduling</Typography>
          {scheduleData.map((schedule: any, index: number) =>
            <>
              <Autocomplete
                disablePortal
                options={["quote", "work", "pickup", "drop off"]}
                onChange={(_e, newValue: any) => updateScheduleData(newValue, index, 'type')}
                renderInput={(params) => <TextField {...params} fullWidth label="Event" />}
                value={schedule.type}
              />
              <DateTimePicker
                renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
                label="Start Time"
                onChange={(e) => updateScheduleData(e, index, "start_time")}
                value={schedule.start_time}
              />
              <DateTimePicker
                renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
                label="End Time"
                onChange={(e) => updateScheduleData(e, index, "end_time")}
                value={schedule.end_time}
              />
            </>
          )}
          <Button
            onClick={() => {
              setScheduleData([
                ...scheduleData,
                {
                  type: "",
                  start_time: new Date(),
                  end_time: new Date(),
                }
              ])
            }}
          >
            Add Schedule
          </Button>
        </Stack>
      </Paper>
      <Button sx={{ m: 1 }} fullWidth variant="contained" onClick={createWithWizard}>
        Complete
      </Button>
    </Container>
  );
}
