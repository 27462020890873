import { Delete } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, Container, Stack, TextField, Button, List, ListItem, IconButton, ListItemText, DialogActions } from "@mui/material";
import { useState, useEffect } from "react";
import useFetch from "use-http";
import baseUrl from "../helpers/urls";

type MasterTaskListModalProps = {
  open: boolean;
  onClose: () => void;
}

export default function MasterTaskListModal({ open, onClose }: MasterTaskListModalProps) {
  const [tasks, setTasks] = useState<{
    id: number;
    name: string;
  }[]>([]);

  const [name, setName] = useState("");

  const { get, post, response, del } = useFetch(`${baseUrl}/tasks`)

  async function init() {
    const taskData = await get();
    if (response.ok) setTasks(taskData.tasks);
  }

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onTaskDelete(id: number) {
    const data = await del(id.toString());
    if (data.deleteCount === 1) {
      setTasks(tasks.filter(({ id: taskId }) => taskId !== id));
    }
  }

  async function onTaskAdd() {
    const taskData = await post({
      task: {
        name
      }
    })
    if (response.ok) {
      setTasks([...tasks, ...taskData.task]);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ alignSelf: "center" }}>Edit Job Statuses</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: 'row' }}>
        <Container maxWidth="md">
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} sx={{ alignItems: "center", mt: 2 }}>
              <TextField fullWidth name="Name" value={name} onChange={(e) => setName(e.target.value)} />
              <Button
                variant="contained"
                onClick={onTaskAdd}
              >
                Add
              </Button>
            </Stack>
            <List>
              {tasks.map(({ name, id }) =>
                <ListItem
                  key={name}
                  secondaryAction={
                    <IconButton onClick={() => onTaskDelete(id)}>
                      <Delete />
                    </IconButton>
                  }
                >
                  <ListItemText primary={name} color="black" />
                </ListItem>
              )}
            </List>
          </Stack>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}