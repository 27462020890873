import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AuthRoutes from "./AuthRoutes";

export default function AuthRouteContainer() {
  return (
    <Container maxWidth='xs'>
      <Typography variant="h2" align="center">
        ContractorGo
      </Typography>
      <AuthRoutes />
    </Container>
  );
}
