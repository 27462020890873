import useFetch from 'use-http';
import { AttachMoney } from "@mui/icons-material";
import { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DatePicker from '@mui/lab/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from "@mui/material/InputAdornment";
import baseUrl from '../helpers/urls';
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export const invoiceStatuses = [
  "created",
  "sent",
  "paid",
  "voided",
  "disputed",
]

type AddInvoiceModalProps = {
  open: boolean;
  onClose: () => void;
  jobId?: string | null;
  customerId?: string | null;
}

export default function AddInvoiceModal({ open, onClose, jobId = null, customerId = null }: AddInvoiceModalProps) {
  const { response: customerResponse, get: getCustomers } = useFetch(`${baseUrl}/customers`);
  const { response: jobResponse, get: getJobs } = useFetch(`${baseUrl}/jobs`);
  const { response, post } = useFetch(`${baseUrl}/invoices`);

  const [customerAutocompleteList, setCustomerAutoCompleteList] = useState<any>([]);
  const [jobAutocompleteList, setJobAutoCompleteList] = useState<any>([]);

  useEffect(() => {
    loadInitialCustomers();
    loadInitialJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadInitialCustomers() {
    const initialCustomers = await getCustomers();
    if (customerResponse.ok) setCustomerAutoCompleteList(
      initialCustomers.customers.map((customer: any) => ({
        label: customer.company_name,
        value: customer.id
      }))
    );
  }

  async function loadInitialJobs() {
    const initialJobs = await getJobs();
    if (jobResponse.ok) setJobAutoCompleteList(
      initialJobs.jobs.map((job: any) => ({
        label: `${new Date(job.start_date).toDateString()} - ${new Date(job.end_date).toDateString()}`,
        value: job.id,
        customerId: job.customer_id,
      }))
    );
  }

  const [formData, setFormData] = useState({
    dueDate: new Date(),
    customerId: customerId === null ? '' : customerId,
    jobId: jobId === null ? '' : jobId,
    cost: 0,
    status: '',
  });

  function updateForm(value: any, fieldName: string) {
    return setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  async function createInvoice() {
    await post(
      {
        invoice: {
          customer_id: formData.customerId,
          due_date: formData.dueDate,
          cost_in_cents: formData.cost * 100,
          status: formData.status,
          job_id: formData.jobId,
        }
      }
    );
    if (response.ok) {
      onClose();
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Invoice</DialogTitle>
      <DialogContent>
        {jobId === null &&
          <>
            <Autocomplete
              sx={{ mt: 2 }}
              disablePortal
              options={customerAutocompleteList}
              onChange={(_e, newValue: any) => updateForm(newValue.value, 'customerId')}
              renderInput={(params) => <TextField {...params} fullWidth label="Customer Reference" />}
            />
            <Autocomplete
              sx={{ mt: 2 }}
              disablePortal
              filterOptions={(options) => {
                return options.filter((option: any) =>
                  option.customerId === formData.customerId
                )
              }}
              options={jobAutocompleteList}
              onChange={(_e, newValue: any) => updateForm(newValue.value, 'jobId')}
              renderInput={(params) => <TextField {...params} fullWidth label="Job" />}
            />
          </>}
        <DatePicker
          renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
          label="Due Date"
          onChange={(date) => updateForm(date, "dueDate")}
          value={formData.dueDate}
        />
        <TextField
          margin="dense"
          label="Cost"
          fullWidth
          variant="standard"
          value={formData.cost}
          type="number"
          onChange={(e) => updateForm(e.target.value, 'cost')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoney />
              </InputAdornment>
            ),
          }}
        />
        <FormControl sx={{ mt: 2 }} fullWidth>
          <InputLabel id="invoice-status-select">Status</InputLabel>
          <Select
            labelId="invoice-status-select"
            label="Status"
            value={formData.status}
            onChange={(e) => updateForm(e.target.value as string, 'status')}
          >
            {invoiceStatuses.map((status) => <MenuItem key={status} value={status}>{status}</MenuItem>)}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={createInvoice}>Create</Button>
      </DialogActions>
    </Dialog>
  );
}
