import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useUser from "../hooks/useUser";
import useFetch from 'use-http';
import { Container, Paper, Stack, TextField } from '@mui/material';
import baseUrl from '../helpers/urls';

export default function Settings() {
  const [user] = useUser();
  const { response: stripeCreationResponse, post: createStripeAccount } = useFetch(`${baseUrl}/stripe/${user.company.id}/create_stripe_account`);
  const { response: logoutResponse, post: postLogout } = useFetch(`${baseUrl}/logout`);

  async function createStripe() {
    const res = await createStripeAccount();
    if (stripeCreationResponse.ok) window.location.replace(res.url);
  }

  async function logout() {
    await postLogout();
    if (logoutResponse.ok) window.location.replace("");
  }

  function loginToStripe() {
    window.location.replace("https://dashboard.stripe.com/");
  }

  return (
    <Container maxWidth="sm">
      <Typography align="center" variant='h5' sx={{ mb: 2 }}>Settings</Typography>
      <Stack spacing={2}>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Typography variant="h6">Company</Typography>
            <TextField label="Company Name" value={user.company.name} disabled />
            <TextField label="Company Address" value={user.company.address} disabled />
          </Stack>
        </Paper>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Typography variant="h6">Integrations</Typography>
            {!user.company.stripe_account_id ?
              <Button variant='contained' onClick={createStripe}>Connect to Stripe</Button> :
              <>
                <TextField label="Stripe ID" fullWidth disabled value={user.company.stripe_account_id} />
                <Button onClick={loginToStripe}>Login to Stripe</Button>
              </>}
          </Stack>
        </Paper>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Typography variant="h6">Account Settings</Typography>
            <TextField label="Username" value={user.username} disabled />
            <TextField label="Email" value={user.email} disabled />
            <TextField label="First Name" value={user.first_name} disabled />
            <TextField label="Last Name" value={user.last_name} disabled />
          </Stack>
        </Paper>
        <Button variant='contained' color='warning' onClick={logout}>Log out</Button>
      </Stack>
    </Container >
  )
}