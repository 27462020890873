import { BrowserRouter } from "react-router-dom";
import { createContext, useState } from 'react';
import RootNavigation from "./navigation";
import { Provider } from "use-http";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import baseUrl from "./helpers/urls";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const userContext = createContext<any>(null);

const theme = createTheme({
  palette: {
    mode: 'light',
  },
});

type UserProviderProps = {
  children: React.ReactElement | React.ReactElement[];
  initialState?: any;
}

function UserProvider({ children, initialState = null }: UserProviderProps) {
  const [user, setUser] = useState(initialState);

  return (
    <userContext.Provider value={[user, setUser]}>
      {children}
    </userContext.Provider>
  )
}

export default function App() {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider maxSnack={3}>
          {/* @ts-ignore-next-line */}
          <Provider options={{ cachePolicy: "no-cache", credentials: "include", headers: { withCredentials: true, "Access-Control-Allow-Credentials": true, "Access-Control-Allow-Origin": ["http://localhost:3000", "http://localhost:3001", baseUrl] } }} >
            <UserProvider>
              <ThemeProvider theme={theme}>
                <RootNavigation />
              </ThemeProvider>
            </UserProvider>
          </Provider>
        </SnackbarProvider>
      </LocalizationProvider>
    </BrowserRouter >
  );
}
