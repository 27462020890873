import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AdminRoutes from './AdminRoutes';
import { Dashboard, Person, Money, Today, Work, Settings, EmojiPeople } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import useUser from '../hooks/useUser';
import { Button, Dialog } from '@mui/material';
import Wizard from '../AdminScreens/Wizard';
import { useSnackbar } from 'notistack';
import Calendar from '../AdminScreens/Calendar';
import Map from '../AdminScreens/Map';


const drawerWidth = 240;

interface Props { }

const menuItems = [
  { title: 'Dashboard', icon: <Dashboard />, url: '' },
  { title: 'Customers', icon: <Person />, url: 'customers' },
  { title: 'Jobs', icon: <Work />, url: 'jobs' },
  { title: 'Invoices', icon: <Money />, url: 'invoices' },
  { title: 'Employees', icon: <EmojiPeople />, url: 'employees' },
  { title: 'Scheduling', icon: <Today />, url: 'scheduling' },
  { title: 'Settings', icon: <Settings />, url: 'settings' },
]

export default function AdminRouteContainer(props: Props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [user] = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const [wizardOpen, setWizardOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);

  const { pathname } = useLocation()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {menuItems.map(({ title, icon, url }, index) =>
          <ListItemButton selected={pathname === `/admin/${url}`} component={Link} to={`/admin/${url}`} key={title}>
            <ListItemIcon>
              {icon}
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItemButton>
        )}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Dialog open={wizardOpen} onClose={() => setWizardOpen(false)} fullScreen={true}>
        <Wizard onClose={(response) => {
          setWizardOpen(false);
          Object.keys(response).forEach((key: string) => {
            if (response[key] !== null) {
              enqueueSnackbar(`Created ${key}!`)
            }
          })
        }} />
      </Dialog>
      <Dialog open={calendarOpen} onClose={() => setCalendarOpen(false)} fullScreen={true}>
        <Calendar onClose={() => setCalendarOpen(false)} />
      </Dialog>
      <Dialog open={mapOpen} onClose={() => setMapOpen(false)} fullScreen={true}>
        <Map onClose={() => setMapOpen(false)} />
      </Dialog>
      <AppBar
        color='primary'
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" flexGrow={1}>
            {user.company.name}
          </Typography>
          <Button
            size="small"
            sx={{ ml: 1 }}
            color="warning"
            variant='contained'
            onClick={() => setMapOpen(true)}
          >
            Map
          </Button>
          <Button
            size="small"
            color="warning"
            variant='contained'
            onClick={() => setCalendarOpen(true)}
            sx={{ ml: 1 }}
          >
            Calendar
          </Button>
          <Button
            size="small"
            sx={{ ml: 1 }}
            color="warning"
            variant='contained'
            onClick={() => setWizardOpen(true)}
          >
            Wizard
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <AdminRoutes />
      </Box>
    </Box>
  );
}
