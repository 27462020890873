import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import useFetch from 'use-http';
import { jobColumns } from './Jobs';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip, PieChart, Pie, Cell } from 'recharts';
import baseUrl from '../helpers/urls';
import { colorList, convertColorToHex } from '../components/ColorPicker';
import { useEffect, useState } from 'react';
import { scheduleColumns } from './Schedules';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import StyledDataGrid from '../components/StyledDataGrid';

export default function Dashboard() {
  const { response: activeJobResponse, loading: activeJobLoading, error: activeJobError } = useFetch(`${baseUrl}/jobs/active`, []);
  const { response: invoiceMonthResponse, loading: invoiceMonthLoading, error: invoiceMonthError } = useFetch(`${baseUrl}/invoices/month`, []);
  const { response: invoiceResponse, loading: invoiceLoading, error: invoiceError } = useFetch(`${baseUrl}/invoices`, []);
  const { response: scheduleResponse, loading: scheduleLoading, error: scheduleError } = useFetch(`${baseUrl}/schedules`, []);

  const [invoicePie, setInvoicePie] = useState<any>([]);
  const [tabValue, setTabValue] = useState("0");

  useEffect(() => {
    if (!invoiceLoading) {
      const data = invoiceResponse.data.invoices.reduce((prev: { name: string, value: number }[], curr: { status: string }) => {
        const previous = prev.filter(({ name }) => name === curr.status)[0]
        return ([
          ...prev.filter(({ name }) => name !== curr.status),
          {
            ...previous,
            value: (previous?.value || 0) + 1,
            name: curr.status,
          }
        ])
      }, [])
      setInvoicePie(data)
    }
  }, [invoiceLoading, invoiceResponse])

  return (
    <TabContext value={tabValue}>
      <TabList sx={{ mt: "-24px", ml: "-24px" }} onChange={(_e, tab) => setTabValue(tab)} aria-label="basic tabs example">
        <Tab label="Upcoming" value="0" />
        <Tab label="Finances" value="1" />
      </TabList>
      <TabPanel value="0">
        <div style={{ flexDirection: "row", display: "flex", justifyContent: "space-around" }}>
          <Typography variant='h6'>{!activeJobLoading && activeJobResponse.data.jobs.length} Active Job(s)</Typography>
          <Typography variant='h6'>Upcoming Meetings</Typography>
        </div>
        {activeJobError && <Typography>Error loading jobs</Typography>}
        {scheduleError && <Typography>Error loading schedules</Typography>}
        <div style={{ flexDirection: "row", display: "flex" }}>
          <StyledDataGrid
            showColumnRightBorder
            showCellRightBorder
            loading={activeJobLoading}
            disableSelectionOnClick
            style={{ height: 'calc(50vh - 220px)' }}
            rows={activeJobResponse?.data?.jobs || []}
            columns={jobColumns}
            rowsPerPageOptions={[5]}
            sx={{ mr: 1 }}
          />
          <StyledDataGrid
            showColumnRightBorder
            showCellRightBorder
            loading={scheduleLoading}
            disableSelectionOnClick
            style={{ height: 'calc(50vh - 220px)' }}
            rows={scheduleResponse?.data?.schedules || []}
            columns={scheduleColumns}
            rowsPerPageOptions={[5]}
            sx={{ ml: 1 }}
          />
        </div>
      </TabPanel>
      <TabPanel value="1">
        {(invoiceMonthError || invoiceError) && <Typography>Error loading invoices</Typography>}
        {invoiceMonthLoading && <CircularProgress />}
        <div style={{ flexDirection: "row", display: "flex" }}>
          {!invoiceMonthLoading &&
            <Paper variant="outlined" sx={{ p: 1, mt: 2, width: "65%" }}>
              <Typography variant='h6' align="center">Income past month: {!invoiceMonthLoading && `$${invoiceMonthResponse.data.total}`}</Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={invoiceMonthResponse.data.invoiceMonth}>
                  <Line dataKey="income" />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="name" angle={-30} />
                  <YAxis />
                  <Tooltip />
                </LineChart>
              </ResponsiveContainer>
            </Paper>}
          {!invoiceLoading &&
            <Paper variant="outlined" sx={{ p: 1, mt: 2, ml: 2, width: "35%" }}>
              <Typography variant='h6' align="center">Invoice breakdown past month</Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart width={400} height={400}>
                  <Pie
                    dataKey="value"
                    nameKey="name"
                    data={invoicePie}
                    isAnimationActive={false}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label={(entry) => entry.name}
                  >
                    {invoicePie.map((_entry: any, index: number) => <Cell fill={convertColorToHex(colorList[index].value)} />)}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Paper>}
        </div>
      </TabPanel>
    </TabContext>
  );
}
