import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useFetch from 'use-http';
import baseUrl from '../helpers/urls';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';
import { GoogleMap, withGoogleMap, withScriptjs, Marker, InfoWindow } from "react-google-maps"
import { convertColorToHex } from '../components/ColorPicker';
import { useState } from 'react';

type MapProps = {
  onClose: () => void;
}

type MapComponentProps = {
  jobMarkers: any[];
  openJobMarker: number | null;
  setOpenJobMarker: any;
}

const MapComponent = withScriptjs(withGoogleMap(({ jobMarkers, openJobMarker, setOpenJobMarker }: MapComponentProps) =>
  <GoogleMap
    defaultZoom={11}
    defaultCenter={jobMarkers.length > 0 ? { lat: jobMarkers[0].lat, lng: jobMarkers[0].lng } : { lat: -34.397, lng: 150.644 }}
  >
    {jobMarkers.map(({ lat, lng, company_name, color, id, address, label }: { lat: number, lng: number, company_name: string, color: string, id: number, address: string, label: string }) => {
      return <Marker
        label={{ text: company_name, fontSize: "18px" }}
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 12,
          fillColor: convertColorToHex(color),
          fillOpacity: 1,
          strokeWeight: 0,
        }}
        position={{ lat, lng }}
        onClick={() => setOpenJobMarker(id)}
      >
        {openJobMarker === id && <InfoWindow onCloseClick={() => setOpenJobMarker(null)}>
          <>
            <Typography>{company_name}</Typography>
            <Typography>{address}</Typography>
            <Typography>Status: {label}</Typography>
          </>
        </InfoWindow>}
      </Marker>
    })}
  </GoogleMap>
))

export default function Map({ onClose }: MapProps) {
  const { response: activeJobResponse, loading: activeJobLoading, error: activeJobError } = useFetch(`${baseUrl}/jobs/active`, []);
  const [openJobMarker, setOpenJobMarker] = useState<number | null>(null);
  console.log(activeJobResponse?.data?.jobs);
  return (
    <>
      {activeJobError && <Typography>Error loading jobs</Typography>}
      {activeJobLoading && <CircularProgress />}
      {!activeJobLoading &&
        <MapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCt4B-TD2jqehrbSe9cgL7RQpuRPiw-LZg&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100vh` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          jobMarkers={activeJobResponse.data.jobs}
          openJobMarker={openJobMarker}
          setOpenJobMarker={setOpenJobMarker}
        />}
      <IconButton color="error" style={{ position: 'absolute', top: 10, right: 56 }} onClick={onClose}>
        <Close color="error" />
      </IconButton>
    </>
  );
}
