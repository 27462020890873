import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../AuthScreens/Login";
import Pay from "../AuthScreens/Pay";
import Register from "../AuthScreens/Register";

export default function AuthRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/pay/:url_code" element={<Pay />} />
      <Route path="/*" element={<Navigate replace to="/login" />} />
    </Routes>
  )
}