import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import useFetch from 'use-http';
import { GridColDef } from '@mui/x-data-grid';
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { Add, Delete } from "@mui/icons-material";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { useState, useEffect, useCallback } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import GoogleMapsAutocomplete from "../components/GoogleMapsAutocomplete";
import baseUrl from '../helpers/urls';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ExportToolbar from "../components/ExportToolbar";
import StyledDataGrid from "../components/StyledDataGrid";

const customerStatuses = [
  "Active",
  "Inactive",
]

const columns: GridColDef[] = [
  { field: 'company_name', headerName: 'Business Name', width: 150, editable: true },
  { field: 'status', headerName: 'Status', width: 100, editable: true, valueOptions: customerStatuses, type: "singleSelect" },
  { field: 'first_name', headerName: 'First Name', width: 150, editable: true },
  { field: 'last_name', headerName: 'Last Name', width: 150, editable: true },
  { field: 'email', headerName: 'Email', width: 250, editable: true },
  { field: 'address', headerName: 'Address', width: 250, editable: true },
  { field: 'phone_number', headerName: 'Phone', width: 150, editable: true, type: "number", renderCell: (params) => <a href={`tel:${params.value}`}>{params.value}</a> },
];

export default function Customers() {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [customers, setCustomers] = useState<any>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<any>([]);
  const { get, response, loading, error, post } = useFetch(`${baseUrl}/customers`);
  const { del, put } = useFetch(`${baseUrl}/customers`);

  const [isDeleting, setIsDeleting] = useState(false);
  const [totalToDelete, setTotalToDelete] = useState(0);
  const [currentlyDeletingIndex, setCurrentlyDeletingIndex] = useState(0);
  const [editCellSnackbar, setEditCellSnackbar] = useState<any>(null);

  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    address: '',
    phoneNumber: '',
    status: 'Created',
  });

  function updateFormField(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  function updateField(value: any, name: string) {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadInitialCustomers() }, []) // componentDidMount

  async function loadInitialCustomers() {
    const initialCustomers = await get();
    if (response.ok) setCustomers(initialCustomers.customers);
  }

  async function createCustomer() {
    const customerData = await post(
      {
        customer:
        {
          email: formData.email,
          first_name: formData.firstName,
          last_name: formData.lastName,
          company_name: formData.companyName,
          address: formData.address,
          phone_number: formData.phoneNumber,
          status: formData.status,
        }
      }
    );
    if (response.ok) {
      setCustomers(customerData.customers);
      setAddModalOpen(false);
    }
  }

  async function deleteCustomers() {
    setIsDeleting(true);
    const oldSelectedCustomers = selectedCustomers;
    setCurrentlyDeletingIndex(1);
    setTotalToDelete(oldSelectedCustomers.length);
    let newCustomers = customers;
    oldSelectedCustomers.map(async (customerId: any, index: number) => {
      const customerData = await del(`${customerId}`);
      if (customerData.deleteCount === 1) {
        newCustomers = newCustomers.filter(({ id }: { id: number }) => id !== customerId);
        setCurrentlyDeletingIndex(index++);
      }
      if (index === oldSelectedCustomers.length) {
        setIsDeleting(false);
        setCustomers(newCustomers);
        setSelectedCustomers([]);
      }
    });
  }

  const handleCellEditCommit = useCallback(
    async (params) => {
      try {
        // Make the HTTP request to save in the backend
        const { customer } = await put(`${params.id}`, {
          customer: {
            [params.field]: params.value,
          }
        });
        setEditCellSnackbar({ children: 'Customer successfully saved', severity: 'success' });
        setCustomers((prev: any) =>
          prev.map((row: any) => (row.id === params.id ? { ...row, ...customer[0] } : row)),
        );
      } catch (error) {
        setEditCellSnackbar({ children: 'Error while saving user', severity: 'error' });
        // Restore the row in case of error
        setCustomers((prev: any) => [...prev]);
      }
    },
    [put],
  );

  return (
    <>
      {!!editCellSnackbar && (
        <Snackbar open onClose={() => setEditCellSnackbar(false)} autoHideDuration={6000}>
          <Alert {...editCellSnackbar} onClose={() => setEditCellSnackbar(false)} />
        </Snackbar>
      )}
      <Typography variant="h5">Customers</Typography>
      {error && <Typography>Error loading customers</Typography>}
      {loading && <CircularProgress />}
      {isDeleting &&
        <Alert severity="info">
          <AlertTitle>Deleting Customers</AlertTitle>
          {currentlyDeletingIndex} / {totalToDelete}
        </Alert>}
      {!loading &&
        <StyledDataGrid
          showColumnRightBorder
          showCellRightBorder
          disableSelectionOnClick
          onCellEditCommit={handleCellEditCommit}
          checkboxSelection
          style={{ height: 'calc(100vh - 220px)' }}
          rows={customers}
          columns={columns}
          selectionModel={selectedCustomers}
          onSelectionModelChange={(selected) => setSelectedCustomers(selected)}
          components={{
            Toolbar: ExportToolbar,
          }}
        />}
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 32, right: 32 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          onClick={() => setAddModalOpen(true)}
          icon={<Add />}
          tooltipTitle="Add Customer"
        />
        <SpeedDialAction
          onClick={() => deleteCustomers()}
          icon={<Delete />}
          tooltipTitle="Delete Selected Customers"
        />
      </SpeedDial>
      <Dialog open={addModalOpen} onClose={() => setAddModalOpen(false)}>
        <DialogTitle>Add Customer</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            name="email"
            onChange={(e) => updateFormField(e)}
          />
          <TextField
            margin="dense"
            label="Customer Name"
            fullWidth
            variant="standard"
            name="companyName"
            onChange={(e) => updateFormField(e)}
          />
          <TextField
            margin="dense"
            label="First Name"
            fullWidth
            variant="standard"
            name="firstName"
            onChange={(e) => updateFormField(e)}
          />
          <TextField
            margin="dense"
            label="Last Name"
            fullWidth
            variant="standard"
            name="lastName"
            onChange={(e) => updateFormField(e)}
          />
          <GoogleMapsAutocomplete
            onChange={(e) => updateField(e?.description || '', 'address')}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            fullWidth
            variant="standard"
            name="phoneNumber"
            onChange={(e) => updateFormField(e)}
          />
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel id="customer-status-select">Status</InputLabel>
            <Select
              labelId="customer-status-select"
              label="Status"
              name="status"
              value={formData.status}
              onChange={(e) => updateFormField(e)}
            >
              {customerStatuses.map((status) => <MenuItem value={status}>{status}</MenuItem>)}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddModalOpen(false)}>Cancel</Button>
          <Button onClick={createCustomer}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
