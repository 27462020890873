import CssBaseline from '@mui/material/CssBaseline';
import Onboarding from '../AdminScreens/Onboarding';
import useUser from '../hooks/useUser';
import useFetch from "use-http";
import AdminRouteContainer from './AdminRouteContainer';
import AuthRouteContainer from './AuthRouteContainer';
import { useEffect } from 'react';
import baseUrl from '../helpers/urls';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

export default function RootNavigation() {
  const [user, setUser] = useUser();
  const { loading, response } = useFetch(`${baseUrl}/`, []);

  useEffect(() => {
    if (!loading && !!response.data && response.data.isAuthenticated) {
      setUser({ ...response.data.user[0], company: response.data.company });
    }
  }, [loading, response.data, setUser])

  if (loading) {
    return (
      <>
        <CssBaseline />
        <Container sx={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size={100} />
        </Container>
      </>
    )
  }

  return (
    <>
      <CssBaseline />
      {user ? user.has_setup_company ? <AdminRouteContainer /> : <Onboarding /> : <AuthRouteContainer />}
    </>
  );
}