import { Routes, Route, Navigate } from "react-router-dom";
import Customers from "../AdminScreens/Customers";
import Dashboard from "../AdminScreens/Dashboard";
import Employees from "../AdminScreens/Employees";
import Invoices from "../AdminScreens/Invoices";
import JobDetails from "../AdminScreens/JobDetails";
import Jobs from "../AdminScreens/Jobs";
import Schedules from "../AdminScreens/Schedules";
import Settings from "../AdminScreens/Settings";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route path="admin">
        <Route index element={<Dashboard />} />
        <Route path="jobs">
          <Route index element={<Jobs />} />
          <Route path=":id" element={<JobDetails />} />
        </Route>
        <Route path="customers" element={<Customers />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="employees" element={<Employees />} />
        <Route path="scheduling" element={<Schedules />} />
        <Route path="settings" element={<Settings />} />
      </Route>
      <Route path="*" element={<Navigate replace to="admin" />} />
    </Routes>
  )
}