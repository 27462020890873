import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import useFetch from 'use-http';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { Add, Delete, Edit, EditAttributes, Task } from "@mui/icons-material";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { useState, useEffect, useCallback } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert, { AlertProps } from "@mui/material/Alert";
import DateTimePicker from '@mui/lab/DateTimePicker';
import DatePicker from '@mui/lab/DatePicker';
import format from 'date-fns/format';
import Autocomplete from '@mui/material/Autocomplete';
import Snackbar from "@mui/material/Snackbar";
import GoogleMapsAutocomplete from "../components/GoogleMapsAutocomplete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { Box, Checkbox, Collapse, Container, FormControlLabel, IconButton, List, ListItem, ListItemText, Paper } from "@mui/material";
import baseUrl from '../helpers/urls';
import ColorPicker, { convertColorToHex, getBackgroundColor, getHoverBackgroundColor } from "../components/ColorPicker";
import ExportToolbar from "../components/ExportToolbar";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from "react-router-dom";
import MasterTaskListModal from "../components/MasterTaskListModal";
import StyledDataGrid from "../components/StyledDataGrid";

const convertTypeToReadable = (type: string) => {
  switch (type) {
    case "string":
      return "text";
    case "number":
      return "Number";
    case "date":
      return "Date";
    case "dateTime":
      return "Date w/ Time";
    case "boolean":
      return "Yes or No";
    default:
      if (type.charAt(0) === "[") {
        const formattedType = JSON.parse(type) as { label: string, color: string }[];
        return formattedType
          .reduce(
            (prev, { label }, index, array) =>
              `${prev}${label}${array.length - 1 !== index ? ',' : ''} `, ''
          );
      }
  }
}

export const jobColumns: GridColDef[] = [
  {
    field: 'action', headerName: "Link", width: 60, align: "center", renderCell: (params) => (
      <Link to={`/admin/jobs/${params.id}`}>
        <LaunchIcon sx={{ mt: 2.5 }} />
      </Link>
    ),
  },
  { field: 'company_name', headerName: 'Customer Name', width: 200 },
  { field: 'address', headerName: 'Address', editable: true, width: 250, renderCell: (params) => <a href={`http://maps.google.com/maps?daddr=${new URLSearchParams(params.value)}&dir_action=navigate`} rel="noreferrer" target="_blank">{params.value}</a> },
  { field: 'start_date', headerName: 'Start Date', type: 'date', editable: true, width: 200, valueFormatter: (params) => params.value ? format(new Date(params.value.toString()), 'PP') : null },
  { field: 'end_date', headerName: 'End Date', type: 'date', editable: true, width: 200, valueFormatter: (params) => params.value ? format(new Date(params.value.toString()), 'PP') : null },
];

type EditModalProps = {
  open: boolean;
  onClose: () => void;
}

type SchemaTypes = 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect';

function EditJobSchemaModal({ open, onClose }: EditModalProps) {
  const [schema, setSchema] = useState<{
    id: number;
    company_id: number;
    field: string;
    label: string;
    type: string;
  }[]>([]);
  const [label, setLabel] = useState("");
  const [type, setType] = useState<SchemaTypes>("string");
  const [selectOptionText, setSelectOptionText] = useState("");
  const [selectOptionColor, setSelectOptionColor] = useState("");
  const [singleSelectList, setSingleSelectList] = useState<{ label: string, color: string }[] | []>([]);

  const { get, post, response, loading, del } = useFetch(`${baseUrl}/jobs/field`)

  async function init() {
    const schemaData = await get();
    if (response.ok) setSchema(schemaData.jobSchema);
  }

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSchemaDelete(id: number) {
    const data = await del(id.toString());
    if (data.deleteCount === 1) {
      setSchema(schema.filter(({ id: schemaId }) => schemaId !== id));
    }
  }

  async function onSchemaAdd() {
    const convertedType = type === "singleSelect" ? JSON.stringify(singleSelectList) : type;
    const schemaData = await post({
      jobSchema: {
        field: label,
        label,
        type: convertedType,
      }
    })
    if (response.ok) {
      setSchema(schemaData.jobSchema);
      setType("string");
      setLabel("");
      setSelectOptionText("");
      setSingleSelectList([]);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ alignSelf: "center" }}>Create Job Fields</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: 'row' }}>
        <Container maxWidth="md">
          <Stack spacing={2} direction="row" sx={{ alignItems: "center", mt: 2 }}>
            <TextField fullWidth label="Name" value={label} onChange={(e) => setLabel(e.target.value)} />
            <FormControl fullWidth>
              <InputLabel id="type-select">Type</InputLabel>
              <Select labelId="type-select" label="Type" value={type} onChange={(e) => setType(e.target.value as SchemaTypes)}>
                <MenuItem value={"string"}>Text</MenuItem>
                <MenuItem value={"number"}>Number</MenuItem>
                <MenuItem value={"date"}>Date</MenuItem>
                <MenuItem value={"dateTime"}>Date w/ Time</MenuItem>
                <MenuItem value={"boolean"}>Yes or No</MenuItem>
                <MenuItem value={"singleSelect"}>Selection List</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Collapse orientation="vertical" in={type === "singleSelect"} mountOnEnter unmountOnExit>
            <Typography align="center">Selection List Creator</Typography>
            <Paper variant="outlined" sx={{ m: 1, p: 2, display: "flex", flexDirection: "row", mt: 0 }}>
              <Stack spacing={1} sx={{ mt: 1 }}>
                <TextField fullWidth label="Option Text" value={selectOptionText} onChange={(e) => setSelectOptionText(e.target.value)} />
                <ColorPicker onChange={(color: string) => setSelectOptionColor(color)} color={selectOptionColor} />
                <Button
                  variant="contained"
                  onClick={() => {
                    setSingleSelectList([...singleSelectList, { label: selectOptionText, color: selectOptionColor }]);
                    setSelectOptionText("");
                  }}
                >
                  Add
                </Button>
              </Stack>
              <List sx={{ flex: 1, ml: 1, minWidth: "200px" }}>
                {singleSelectList.map(({ label, color }, index) =>
                  <ListItem
                    dense
                    sx={{
                      bgcolor: (theme) =>
                        getBackgroundColor(convertColorToHex(color), theme.palette.mode),
                    }}
                    key={label}
                    secondaryAction={
                      <IconButton onClick={() => setSingleSelectList(singleSelectList.filter(({ label: lab }) => lab !== label))}>
                        <Delete />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={label} secondary={index + 1} color="black" />
                  </ListItem>
                )}
              </List>
            </Paper>
          </Collapse>
          <Button sx={{ mt: 2 }} fullWidth onClick={onSchemaAdd} variant="contained">Add Field</Button>
          <Typography sx={{ mt: 1, mb: 1 }} variant="h6" align="center">Additional Job Fields</Typography>
          {!loading &&
            <List>
              {schema.map(({ label, field, type, id }) =>
                <ListItem
                  key={field}
                  secondaryAction={
                    <IconButton>
                      <Delete onClick={() => onSchemaDelete(id)} />
                    </IconButton>
                  }
                >
                  <ListItemText primary={label} secondary={convertTypeToReadable(type)} />
                </ListItem>
              )}
            </List>}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog >
  )
}

function EditStatusesModal({ open, onClose }: EditModalProps) {
  const [statuses, setStatuses] = useState<{
    id: number;
    company_id: number;
    label: string;
    index: number;
    disabled: boolean;
    color: string;
  }[]>([]);

  const [label, setLabel] = useState("");
  const [statusColor, setStatusColor] = useState("");

  const { get, post, response, del } = useFetch(`${baseUrl}/job_statuses`)

  async function init() {
    const statusData = await get();
    if (response.ok) setStatuses(statusData.jobStatuses);
  }

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSchemaDelete(id: number) {
    const data = await del(id.toString());
    if (data.deleteCount === 1) {
      setStatuses(statuses.filter(({ id: statusId }) => statusId !== id));
    }
  }

  async function onSchemaAdd() {
    const statusData = await post({
      jobStatus: {
        label,
        index: statuses.length,
        disabled: false,
        color: statusColor,
      }
    })
    if (response.ok) {
      setStatuses(statusData.jobStatuses);
      setStatusColor("");
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ alignSelf: "center" }}>Edit Job Statuses</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: 'row' }}>
        <Container maxWidth="md">
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} sx={{ alignItems: "center", mt: 2 }}>
              <TextField fullWidth label="Label" value={label} onChange={(e) => setLabel(e.target.value)} />
              <ColorPicker onChange={(color: string) => setStatusColor(color)} color={statusColor} />
              <Button
                variant="contained"
                onClick={onSchemaAdd}
              >
                Add
              </Button>
            </Stack>
            <List>
              {statuses.map(({ label, color, id }) =>
                <ListItem
                  sx={{
                    bgcolor: (theme) =>
                      getBackgroundColor(convertColorToHex(color), theme.palette.mode),
                  }}
                  key={label}
                  secondaryAction={
                    <IconButton onClick={() => onSchemaDelete(id)}>
                      <Delete />
                    </IconButton>
                  }
                >
                  <DragIndicatorIcon />
                  <ListItemText primary={label} color="black" />
                </ListItem>
              )}
            </List>
          </Stack>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default function Jobs() {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [additionalFields, setAdditionalFields] = useState<GridColDef[]>([]);
  const [additionalFieldModalOpen, setAdditionalFieldModalOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [jobs, setJobs] = useState<any>([]);
  const [selectedJobs, setSelectedJobs] = useState<GridSelectionModel>([]);
  const { response: customerResponse, get: getCustomers } = useFetch(`${baseUrl}/customers`);
  const { get, response, loading, error, post } = useFetch(`${baseUrl}/jobs`);
  const { get: getAdditionalFieldSchema, response: additionalFieldSchemaResponse } = useFetch(`${baseUrl}/jobs/field`);
  const { post: updateAdditionalField } = useFetch(`${baseUrl}/jobs/field/update`);
  const {
    // del, 
    put } = useFetch(`${baseUrl}/jobs`);

  // const [isDeleting, setIsDeleting] = useState(false);
  // const [totalToDelete, setTotalToDelete] = useState(0);
  // const [currentlyDeletingIndex, setCurrentlyDeletingIndex] = useState(0);
  const [customerAutocompleteList, setCustomerAutoCompleteList] = useState<{ label: string, value: number }[]>([]);
  const [editCellSnackbar, setEditCellSnackbar] = useState<{ children: string, severity: string } | null | boolean>(null);

  async function loadInitialCustomers() {
    const initialCustomers = await getCustomers();
    if (customerResponse.ok) setCustomerAutoCompleteList(
      initialCustomers.customers.map((customer: any) => ({
        label: customer.company_name,
        value: customer.id
      }))
    );
  }

  const [formData, setFormData] = useState({
    startDate: new Date().toDateString(),
    endDate: new Date().toDateString(),
    customerId: "",
    address: "",
    statusId: "",
  });

  function updateForm(value: any, fieldName: string) {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  const [additionalFormData, setAdditionalFormData] = useState<any>({});

  function updateAdditionalForm(value: any, fieldName: string) {
    setAdditionalFormData({
      ...additionalFormData,
      [fieldName]: value,
    })
  }

  useEffect(() => {
    loadInitialJobs();
    loadInitialCustomers();
    loadAdditionalFieldSchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalFieldModalOpen, statusModalOpen])

  async function loadInitialJobs() {
    const initialJobs = await get();
    if (response.ok) setJobs(initialJobs.jobs);
  }

  async function loadAdditionalFieldSchema() {
    const additionalFieldResponse = await getAdditionalFieldSchema();
    if (additionalFieldSchemaResponse.ok) {
      setAdditionalFields(
        additionalFieldResponse.jobSchema.map(({ label, type }: { label: string, type: string }) => ({
          field: label, headerName: label, type: type.charAt(0) === "[" ? "singleSelect" : type, editable: true, width: 200, ...(type.charAt(0) === "[" ? { valueOptions: JSON.parse(type).reduce((prev: string[], { label }: { label: string }) => [...prev, label], []) } : {}),
        }))
      )
    };
  }

  async function createJob() {
    const jobData = await post({
      job: {
        customer_id: formData.customerId,
        start_date: formData.startDate,
        end_date: formData.endDate,
        address: formData.address,
        status_id: formData.statusId,
      },
      additionFields: additionalFormData,
    });
    if (response.ok) {
      setJobs(jobData.jobs);
      setAddModalOpen(false);
    }
  }

  const handleCellEditCommit = useCallback(
    async (params, additionalFields: GridColDef[]) => {
      try {
        if (!additionalFields.find(({ field }) => field === params.field)) {
          const { job } = await put(`${params.id}`, {
            job: {
              [params.field]: params.value,
            }
          });
          setJobs((prev: any) =>
            prev.map((row: any) => (row.id === params.id ? { ...row, ...job[0] } : row)),
          );
        } else {
          await updateAdditionalField(`${params.id}`, {
            jobSchemaValue: {
              value: params.value,
              label: params.field,
              field: params.field,
              job_id: params.id,
            }
          })
        }
        setEditCellSnackbar({ children: 'Job successfully saved', severity: 'success' });
      } catch (error) {
        setEditCellSnackbar({ children: 'Error while saving user', severity: 'error' });
        // Restore the row in case of error
        setJobs((prev: any) => [...prev]);
      }
    },
    [put, updateAdditionalField],
  );

  // async function deleteJobs() {
  //   setIsDeleting(true);
  //   const oldSelectedJobs = selectedJobs;
  //   setCurrentlyDeletingIndex(1);
  //   setTotalToDelete(oldSelectedJobs.length);
  //   let newJobs = jobs;
  //   oldSelectedJobs.map(async (jobId: any, index: number) => {
  //     const jobData = await del(`${jobId}`);
  //     if (jobData.deleteCount === 1) {
  //       newJobs = newJobs.filter(({ id }: { id: number }) => id !== jobId);
  //       setCurrentlyDeletingIndex(index++);
  //     }
  //     if (index === oldSelectedJobs.length) {
  //       setIsDeleting(false);
  //       setJobs(newJobs);
  //       setSelectedJobs([]);
  //     }
  //   });
  // }

  return (
    <Box
      sx={{
        '& .table-row-blue': {
          bgcolor: (theme) =>
            getBackgroundColor(convertColorToHex("blue"), theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(convertColorToHex("blue"), theme.palette.mode),
          },
        },
        '& .table-row-red': {
          bgcolor: (theme) =>
            getBackgroundColor(convertColorToHex("red"), theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                convertColorToHex("red"),
                theme.palette.mode,
              ),
          },
        },
        '& .table-row-green': {
          bgcolor: (theme) =>
            getBackgroundColor(convertColorToHex("green"), theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                convertColorToHex("green"),
                theme.palette.mode,
              ),
          },
        },
        '& .table-row-purple': {
          bgcolor: (theme) =>
            getBackgroundColor(convertColorToHex("purple"), theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(convertColorToHex("purple"), theme.palette.mode),
          },
        },
        '& .table-row-yellow': {
          bgcolor: (theme) =>
            getBackgroundColor(convertColorToHex("yellow"), theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(convertColorToHex("yellow"), theme.palette.mode),
          },
        },
        '& .table-row-orange': {
          bgcolor: (theme) =>
            getBackgroundColor(convertColorToHex("orange"), theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(convertColorToHex("orange"), theme.palette.mode),
          },
        },
      }}
    >
      <MasterTaskListModal open={taskModalOpen} onClose={() => setTaskModalOpen(false)} />
      {!!editCellSnackbar && (
        <Snackbar open onClose={() => setEditCellSnackbar(false)} autoHideDuration={6000}>
          <Alert {...editCellSnackbar as AlertProps} onClose={() => setEditCellSnackbar(false)} />
        </Snackbar>
      )}
      <Typography variant="h5">Jobs</Typography>
      {error && <Typography>Error loading jobs</Typography>}
      {loading && <CircularProgress />}
      {/* {isDeleting &&
        <Alert severity="info">
          <AlertTitle>Deleting Jobs</AlertTitle>
          {currentlyDeletingIndex} / {totalToDelete}
        </Alert>} */}
      {!loading &&
        <StyledDataGrid
          showColumnRightBorder
          showCellRightBorder
          disableSelectionOnClick
          checkboxSelection
          style={{ height: 'calc(100vh - 220px)' }}
          rows={jobs}
          columns={[
            {
              field: 'status_id',
              headerName: 'Status',
              width: 200,
              editable: true,
              type: "singleSelect",
              valueOptions: response?.data?.jobStatuses.map(({ label, id }: any) => ({ label, value: id })) || [],
              valueGetter: (params) => params.row.label,
            },
            ...jobColumns,
            ...additionalFields,
          ]}
          selectionModel={selectedJobs}
          onCellEditCommit={(params) => handleCellEditCommit(params, additionalFields)}
          onSelectionModelChange={setSelectedJobs}
          getRowClassName={(params) => `table-row-${params.row.color}`}
          components={{
            Toolbar: ExportToolbar,
          }}
        />}
      <SpeedDial
        ariaLabel="Jobs SpeedDial"
        sx={{ position: 'absolute', bottom: 32, right: 32 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          onClick={() => setAddModalOpen(true)}
          icon={<Add />}
          tooltipTitle="Add Job"
        />
        {/* <SpeedDialAction
          onClick={() => deleteJobs()}
          icon={<Delete />}
          tooltipTitle="Delete Selected Jobs"
        /> */}
        <SpeedDialAction
          onClick={() => setAdditionalFieldModalOpen(true)}
          icon={<Edit />}
          tooltipTitle="Edit Additional Fields"
        />
        <SpeedDialAction
          onClick={() => setStatusModalOpen(true)}
          icon={<EditAttributes />}
          tooltipTitle="Edit Statuses"
        />
        <SpeedDialAction
          onClick={() => setTaskModalOpen(true)}
          icon={<Task />}
          tooltipTitle="Edit Tasks"
        />
      </SpeedDial>
      <EditJobSchemaModal
        open={additionalFieldModalOpen}
        onClose={() => setAdditionalFieldModalOpen(false)}
      />
      <EditStatusesModal
        open={statusModalOpen}
        onClose={() => setStatusModalOpen(false)}
      />
      <Dialog open={addModalOpen} onClose={() => setAddModalOpen(false)}>
        <DialogTitle>Add Job</DialogTitle>
        <DialogContent>
          <Autocomplete
            disablePortal
            options={customerAutocompleteList}
            onChange={(_e, newValue: any) => updateForm(newValue.value, 'customerId')}
            renderInput={(params) => <TextField {...params} fullWidth label="Customer Reference" />}
          />
          <GoogleMapsAutocomplete
            onChange={(e) => updateForm(e?.description || '', 'address')}
          />
          <DatePicker
            renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
            label="Start Date"
            onChange={(date) => updateForm(date, "startDate")}
            value={formData.startDate}
          />
          <DatePicker
            renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
            label="End Date"
            onChange={(date) => updateForm(date, "endDate")}
            value={formData.endDate}
          />
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel id="status-select">Status</InputLabel>
            <Select
              labelId="status-select"
              label="Status"
              onChange={(e) => updateForm(e.target.value as string, "statusId")}
            >
              {response.data?.jobStatuses?.map((option: any) => <MenuItem value={option.id}>{option.label}</MenuItem>) || []}
            </Select>
          </FormControl>
          {additionalFields.map(({ type = "", field, valueOptions = [] }) => {
            switch (type) {
              case "string":
                return <TextField
                  margin="dense"
                  label={field}
                  fullWidth
                  variant="standard"
                  name={field}
                  onChange={(e) => updateAdditionalForm(e.target.value, field)}
                  value={additionalFormData[field] || ""}
                />;
              case "number":
                return <TextField
                  margin="dense"
                  label={field}
                  fullWidth
                  type="number"
                  variant="standard"
                  name={field}
                  onChange={(e) => updateAdditionalForm(e.target.value, field)}
                  value={additionalFormData[field] || 0}
                />;
              case "date":
                return <DatePicker
                  renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
                  label={field}
                  onChange={(date) => updateAdditionalForm(date, field)}
                  value={additionalFormData[field] || new Date()}
                />;
              case "dateTime":
                return <DateTimePicker
                  renderInput={(props) => <TextField style={{ marginTop: "16px" }} fullWidth {...props} />}
                  label={field}
                  onChange={(date) => updateAdditionalForm(date, field)}
                  value={additionalFormData[field] || new Date()}
                />;
              case "boolean":
                return <FormControlLabel
                  control={
                    <Checkbox
                      value={additionalFormData[field] || false}
                      onChange={(_e, checked) => updateAdditionalForm(checked, field)}
                    />}
                  label={field}
                />
              case "singleSelect":
                const options = Array.isArray(valueOptions) ? valueOptions : [];
                return <FormControl sx={{ mt: 2 }} fullWidth>
                  <InputLabel id={`${field}-select`}>{field}</InputLabel>
                  <Select
                    fullWidth
                    labelId={`${field}-select`}
                    label={field}
                    onChange={(e) => updateAdditionalForm(e.target.value as string, field)}
                  >
                    {options.map((option) => <MenuItem value={option.toString()}>{option}</MenuItem>)}
                  </Select>
                </FormControl>;
              default:
                return <></>;
            }
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddModalOpen(false)}>Cancel</Button>
          <Button onClick={createJob}>Create</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
