import { darken, lighten } from '@mui/material/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export const convertColorToHex = (color: string) => {
  switch (color) {
    case "red":
      return "#FF0000";
    case "blue":
      return "#0000FF";
    case "green":
      return "#00FF00";
    case "purple":
      return "#800080";
    case "yellow":
      return "#FFFF00";
    case "orange":
      return "#FFA500";
    default:
      return "#fff"
  }
}

export const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.5);

export const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.6);

export const colorList = [
  { value: "red", label: "Red" },
  { value: "blue", label: "Blue" },
  { value: "green", label: "Green" },
  { value: "purple", label: "Purple" },
  { value: "yellow", label: "Yellow" },
  { value: "orange", label: "Orange" },
]

const ColorPicker = ({ onChange, color }: { onChange: (color: string) => void, color: string }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="color-select">Color</InputLabel>
      <Select
        labelId="color-select"
        label="Color"
        value={color}
        onChange={(e) => onChange(e.target.value as string)}
      >
        {colorList.map(({ value, label }) => <MenuItem value={value}>{label}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default ColorPicker;
