import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useFetch from 'use-http';
import baseUrl from '../helpers/urls';
import { useParams } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js/pure';
import '@stripe/stripe-js';
// import redirectToCheckout from '@stripe/stripe-js';

export default function Pay() {
  const { url_code } = useParams();
  const { response, loading, error } = useFetch(`${baseUrl}/stripe/pay/${url_code}`, [])
  const { get } = useFetch(`${baseUrl}/stripe/create-checkout-session/${url_code}`)

  const redirectToCheckout = async () => {
    const response = await get();
    const stripeURL =
      process.env.NODE_ENV !== "development" ?
        "pk_live_51KC0MnHW9fuzvSim3bdqjk8V4WxVW1F41XCN1GFH7vAOGTZCxo7oTZ2mKXDhHcxTURA8CfwDTnyXzzVlKNGXY19m00w9Z8aTrB"
        : "pk_test_51KC0MnHW9fuzvSim5mX0pgepmSH7ADBpfjovMlONg5s0Jd81qc5rCYq5Y8Yu4T9HKjin0sf41G1CMP00KamSTRes00KFrZ0Z13"

    const stripe = await loadStripe(stripeURL, {
      stripeAccount: response.account
    });
    if (stripe) {
      stripe.redirectToCheckout({ sessionId: response.sessionId });
    }
  }

  return (
    <Stack spacing={2}>
      {!loading && <><Typography variant="h5">Company: {response.data.company_name}</Typography>
        <Typography variant="h5">Cost: {response.data.cost_in_cents / 100} USD</Typography></>}
      <Button onClick={redirectToCheckout} variant="contained">
        Pay
      </Button>
      {loading && <CircularProgress />}
      {error && <Typography>Error Loading Checkout</Typography>}
    </Stack>
  );
}
