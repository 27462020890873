import { AttachMoney } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, TextField, InputAdornment, DialogActions, Button, Autocomplete } from "@mui/material"
import { useEffect, useState } from "react";
import useFetch from "use-http";
import baseUrl from "../helpers/urls";

type AddTimesheetModalProps = {
  open: boolean;
  onClose: () => void;
  jobId: string;
}

export default function AddTimesheetModal({ open, onClose, jobId }: AddTimesheetModalProps) {

  const { response, post } = useFetch(`${baseUrl}/timesheets`);
  const { response: taskResponse, loading: taskLoading } = useFetch(`${baseUrl}/tasks`, []);
  const { response: employeeResponse, loading: employeeLoading } = useFetch(`${baseUrl}/employees`, []);

  const [taskAutocompleteList, setTaskAutoCompleteList] = useState<{ label: string, value: number }[]>([]);
  const [employeeAutocompleteList, setEmployeeAutoCompleteList] = useState<{ label: string, value: number }[]>([]);

  useEffect(() => {
    async function loadEmployees() {
      setEmployeeAutoCompleteList(
        employeeResponse.data.employees.map((employee: any) => ({
          label: `${employee.first_name} ${employee.last_name}`,
          value: employee.id
        }))
      );
    }

    async function loadTasks() {
      setTaskAutoCompleteList(
        taskResponse.data.tasks.map((task: any) => ({
          label: task.name,
          value: task.id
        }))
      );
    }
    if (!employeeLoading) {
      loadEmployees();
    }
    if (!taskLoading) {
      loadTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeLoading, taskLoading])

  const [formData, setFormData] = useState({
    task_id: "",
    employee_id: "",
    hours_worked: 0,
  });

  function updateForm(value: any, fieldName: string) {
    return setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  async function createTimesheet() {
    await post(
      {
        timesheet: {
          task_id: formData.task_id,
          employee_id: formData.employee_id,
          hours_worked: formData.hours_worked,
          job_id: jobId,
        }
      }
    );
    if (response.ok) {
      onClose();
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Timesheet</DialogTitle>
      <DialogContent>
        <Autocomplete
          sx={{ mt: 2 }}
          disablePortal
          options={taskAutocompleteList}
          onChange={(_e, newValue: any) => updateForm(newValue.value, 'task_id')}
          renderInput={(params) => <TextField {...params} fullWidth label="Task" />}
          fullWidth
        />
        <Autocomplete
          sx={{ mt: 2 }}
          disablePortal
          options={employeeAutocompleteList}
          onChange={(_e, newValue: any) => updateForm(newValue.value, 'employee_id')}
          renderInput={(params) => <TextField {...params} fullWidth label="Employee" />}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Hours Worked"
          fullWidth
          variant="standard"
          value={formData.hours_worked}
          type="number"
          onChange={(e) => updateForm(e.target.value, 'hours_worked')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoney />
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={createTimesheet}>Create</Button>
      </DialogActions>
    </Dialog>
  )
}