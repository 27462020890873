import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import useFetch from 'use-http';
import useUser from "../hooks/useUser";
import baseUrl from '../helpers/urls';

export default function Login() {
  const { post, response, loading, error } = useFetch(`${baseUrl}/login`)

  const [, setUser] = useUser();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  async function login() {
    const loginData = await post({ username, password });
    if (response.ok) setUser({ ...loginData.user[0], company: loginData.company });
  }

  return (
    <Stack spacing={2}>
      <TextField label="username" value={username} onChange={e => setUsername(e.target.value)} />
      <TextField label="password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
      <Button variant="contained" onClick={login}>
        Login
      </Button>
      <Button variant="outlined" href="/register">
        Register now
      </Button>
      {loading && <CircularProgress />}
      {error && <Typography>Error Logging in</Typography>}
    </Stack>
  );
}
