import { useEffect, useState } from 'react';
import { Calendar as BigCalendar, Views, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import useFetch from 'use-http';
import baseUrl from '../helpers/urls';

/* @ts-ignore-next-line */
let allViews = Object.keys(Views).map((k: any) => Views[k]) as any

const locales = {
  'en-US': enUS,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

type CalendarProps = {
  onClose: () => void;
}

export default function Calendar({ onClose }: CalendarProps) {
  const { get: getJobs, response: jobResponse } = useFetch(`${baseUrl}/jobs`);
  const { get: getSchedules, response: scheduleResponse } = useFetch(`${baseUrl}/schedules`);
  const { get: getInvoices, response: invoicesResponse } = useFetch(`${baseUrl}/invoices`);

  const [loading, setLoading] = useState(true);

  const [events, setEvents] = useState<any>([]);

  async function loadCalendarData() {
    setLoading(true);
    const [{ jobs }, { schedules }, { invoices }] = await Promise.all([getJobs(), getSchedules(), getInvoices()]);

    if (jobResponse.ok && scheduleResponse.ok && invoicesResponse.ok) {
      setEvents([
        ...jobs.map((job: any, index: number) => ({
          id: index,
          title: `Job: ${job.company_name}`,
          allDay: true,
          start: new Date(job.start_date),
          end: new Date(job.end_date),
        })),
        ...schedules.map((schedule: any, index: number) => ({
          id: index + jobs.length,
          title: `${schedule.type}: ${schedule.company_name}`,
          start: new Date(schedule.start_time),
          end: new Date(schedule.end_time),
        })),
        ...invoices.map((invoice: any, index: number) => ({
          id: index + jobs.length + schedules.length,
          title: `Invoice Due: ${invoice.company_name}`,
          allDay: true,
          start: new Date(invoice.due_date),
          end: new Date(invoice.due_date),
        })),
      ])
    }
    setLoading(false);
  }

  useEffect(() => {
    loadCalendarData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <IconButton style={{ position: 'absolute', top: 4, right: 4 }} onClick={onClose}>
        <Close />
      </IconButton>
      {!loading && <BigCalendar
        style={{ marginTop: 64 }}
        events={events}
        views={allViews}
        step={60}
        showMultiDayTimes
        // max={dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours')}
        defaultDate={new Date()}
        // components={{
        //   timeSlotWrapper: ColoredDateCellWrapper,
        // }}
        localizer={localizer}
      />}
    </>
  )
}