import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import useFetch from 'use-http';
import useUser from "../hooks/useUser";
import baseUrl from '../helpers/urls';
import GoogleMapsAutocomplete from "../components/GoogleMapsAutocomplete";

export default function Onboarding() {
  const [user, setUser] = useUser();
  const { post, response, loading, error } = useFetch(`${baseUrl}/users/${user.id}/companies`)

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');

  async function registerCompany() {
    const companyData = await post({ company: { name, address } });
    if (response.ok) setUser(companyData.user[0]);
  }

  return (
    <Container maxWidth="sm">
      <Stack spacing={2}>
        <Typography variant="h5">Let's setup your company!</Typography>
        <TextField label="company name" value={name} onChange={e => setName(e.target.value)} />
        <GoogleMapsAutocomplete
          onChange={(e) => setAddress(e?.description || '')}
        />
        <Button variant="contained" onClick={registerCompany}>
          Create Company
        </Button>
        {loading && <CircularProgress />}
        {error && <Typography>Error creating company</Typography>}
      </Stack>
    </Container>
  );
}
