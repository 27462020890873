import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import useFetch from 'use-http';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { Add, Delete } from "@mui/icons-material";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { useState, useEffect, useCallback } from "react";
import TextField from '@mui/material/TextField';
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import format from 'date-fns/format';
import Snackbar from "@mui/material/Snackbar";
import baseUrl from '../helpers/urls';
import ExportToolbar from "../components/ExportToolbar";
import AddInvoiceModal from "../components/AddInvoiceModal";
import StyledDataGrid from "../components/StyledDataGrid";

export const invoiceStatuses = [
  "created",
  "sent",
  "paid",
  "voided",
  "disputed",
]

const invoiceColumns: GridColDef[] = [
  { field: 'company_name', headerName: 'Customer Name', width: 200 },
  { field: 'due_date', headerName: 'Due Date', type: 'date', editable: true, width: 200, valueFormatter: (params) => params.value ? format(new Date(params.value.toString()), 'PP') : null },
  { field: 'cost_in_cents', headerName: 'Cost', type: 'number', width: 85, editable: true, valueFormatter: (params) => `$${params.value as number / 100}`, renderEditCell: renderCostEditInputCell },
  { field: 'status', headerName: 'Status', width: 100, editable: true, valueOptions: invoiceStatuses, type: "singleSelect" },
];

function CostEditInputCell(props: GridRenderCellParams<number>): JSX.Element {
  const { id, value, api, field } = props;

  const handleChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    api.setEditCellValue({ id, field, value: Number(parseFloat(event.target.value) * 100) }, event);
  };

  return (
    <TextField
      value={value / 100}
      onChange={handleChange}
      type="number"
    />
  );
}

export function renderCostEditInputCell(params: any) {
  return <CostEditInputCell {...params} />;
}

export default function Invoices() {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [invoices, setInvoices] = useState<any>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<any>([]);
  const { get, response, loading, error } = useFetch(`${baseUrl}/invoices`);
  const { del, put } = useFetch(`${baseUrl}/invoices`);

  const [isDeleting, setIsDeleting] = useState(false);
  const [totalToDelete, setTotalToDelete] = useState(0);
  const [currentlyDeletingIndex, setCurrentlyDeletingIndex] = useState(0);
  const [editCellSnackbar, setEditCellSnackbar] = useState<any>(null);

  useEffect(() => {
    if (!addModalOpen) {
      loadInitialInvoices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addModalOpen]);

  useEffect(() => {
    loadInitialInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadInitialInvoices() {
    const initialInvoices = await get();
    if (response.ok) setInvoices(initialInvoices.invoices);
  }

  const handleCellEditCommit = useCallback(
    async (params) => {
      try {
        // Make the HTTP request to save in the backend
        const { invoice } = await put(`${params.id}`, {
          invoice: {
            [params.field]: params.value,
          }
        });
        setEditCellSnackbar({ children: 'Invoice successfully saved', severity: 'success' });
        setInvoices((prev: any) =>
          prev.map((row: any) => (row.id === params.id ? { ...row, ...invoice[0] } : row)),
        );
      } catch (error) {
        setEditCellSnackbar({ children: 'Error while saving user', severity: 'error' });
        // Restore the row in case of error
        setInvoices((prev: any) => [...prev]);
      }
    },
    [put],
  );

  async function deleteInvoices() {
    setIsDeleting(true);
    const oldSelectedInvoices = selectedInvoices;
    setCurrentlyDeletingIndex(1);
    setTotalToDelete(oldSelectedInvoices.length);
    let newInvoices = invoices;
    oldSelectedInvoices.map(async (invoiceId: any, index: number) => {
      const invoiceData = await del(`${invoiceId}`);
      if (invoiceData.deleteCount === 1) {
        newInvoices = newInvoices.filter(({ id }: { id: number }) => id !== invoiceId);
        setCurrentlyDeletingIndex(index++);
      }
      if (index === oldSelectedInvoices.length) {
        setIsDeleting(false);
        setInvoices(newInvoices);
        setSelectedInvoices([]);
      }
    });
  }

  return (
    <>
      {!!editCellSnackbar && (
        <Snackbar open onClose={() => setEditCellSnackbar(false)} autoHideDuration={6000}>
          <Alert {...editCellSnackbar} onClose={() => setEditCellSnackbar(false)} />
        </Snackbar>
      )}
      <Typography variant="h5">Invoices</Typography>
      {error && <Typography>Error loading invoices</Typography>}
      {loading && <CircularProgress />}
      {isDeleting &&
        <Alert severity="info">
          <AlertTitle>Deleting Invoices</AlertTitle>
          {currentlyDeletingIndex} / {totalToDelete}
        </Alert>}
      {!loading &&
        <StyledDataGrid
          showColumnRightBorder
          showCellRightBorder
          disableSelectionOnClick
          checkboxSelection
          style={{ height: 'calc(100vh - 220px)' }}
          rows={invoices}
          columns={invoiceColumns}
          selectionModel={selectedInvoices}
          onCellEditCommit={handleCellEditCommit}
          onSelectionModelChange={(selected) => setSelectedInvoices(selected)}
          components={{
            Toolbar: ExportToolbar,
          }}
        />}
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 32, right: 32 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          onClick={() => setAddModalOpen(true)}
          icon={<Add />}
          tooltipTitle="Add Invoice"
        />
        <SpeedDialAction
          onClick={() => deleteInvoices()}
          icon={<Delete />}
          tooltipTitle="Delete Selected Invoices"
        />
      </SpeedDial>
      <AddInvoiceModal onClose={() => setAddModalOpen(false)} open={addModalOpen} />
    </>
  );
}
