import { Button, CircularProgress, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from 'use-http';
import AddInvoiceModal from '../components/AddInvoiceModal';
import AddMaterialCostModal from '../components/AddMaterialCostModal';
import AddTimesheetModal from '../components/AddTimesheetModal';
import StyledDataGrid from '../components/StyledDataGrid';
import baseUrl from '../helpers/urls';
import { invoiceStatuses, renderCostEditInputCell } from './Invoices';

const invoiceColumns: GridColDef[] = [
  { field: 'due_date', headerName: 'Due Date', type: 'date', width: 200, valueFormatter: (params) => params.value ? format(new Date(params.value.toString()), 'PP') : null },
  { field: 'cost_in_cents', headerName: 'Cost', type: 'number', width: 150, valueFormatter: (params) => `$${params.value as number / 100}`, renderEditCell: renderCostEditInputCell, align: "left", headerAlign: "left" },
  { field: 'status', headerName: 'Status', width: 150, valueOptions: invoiceStatuses, type: "singleSelect" },
];

// const actionColumns: GridActionsColDef = {
//   field: 'actions', getActions: () => [
//     // <GridActionsCellItem icon={<Delete />} onClick={() => { }} label="Delete" />,
//   ], type: "actions", width: 65
// }

const materialCostsColumns: GridColDef[] = [
  { field: "description", headerName: "Description", width: 150 },
  { field: "cost_in_cents", headerName: "Cost", width: 150, type: "number", valueFormatter: (params) => `$${params.value as number / 100}`, renderEditCell: renderCostEditInputCell, align: "left", headerAlign: "left" },
  { field: "supplier", headerName: "Supplier", width: 150 },
]

const timesheetsColumns: GridColDef[] = [
  { field: "employee", headerName: "Employee", width: 200, valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}` },
  { field: "hours_worked", headerName: "Hours", width: 200, type: "number", align: "left", headerAlign: "left" },
  { field: "task_name", headerName: "Task", width: 200 },
]

export default function JobDetails() {
  const params = useParams();
  const [materialCostsModalOpen, setMaterialCostsModalOpen] = useState(false);
  const [timesheetModalOpen, setTimesheetModalOpen] = useState(false);
  const [addInvoiceModalOpen, setAddInvoiceModalOpen] = useState(false);

  const { response, loading } = useFetch(`${baseUrl}/jobs/details/${params.id}`, [materialCostsModalOpen, timesheetModalOpen, addInvoiceModalOpen]);

  if (loading && !response.data) {
    return (
      <CircularProgress />
    )
  }

  return (
    <>
      <AddInvoiceModal customerId={response.data.job.customer_id} jobId={params.id || ""} open={addInvoiceModalOpen} onClose={() => setAddInvoiceModalOpen(false)} />
      <AddTimesheetModal jobId={params.id || ""} open={timesheetModalOpen} onClose={() => setTimesheetModalOpen(false)} />
      <AddMaterialCostModal jobId={params.id || ""} open={materialCostsModalOpen} onClose={() => setMaterialCostsModalOpen(false)} />
      <div style={{ flexDirection: "row", display: "flex", justifyContent: "space-between", marginBottom: 16, alignItems: "center" }}>
        <Typography variant="h6">{response.data.job.company_name}</Typography>
        <Typography variant="body1">Scheduled for {format(new Date(response.data.job.start_date.toString()), 'PP')} through {format(new Date(response.data.job.end_date.toString()), 'PP')}</Typography>
      </div>
      <Paper>
        <Stepper variant="outlined" sx={{ p: 2 }} activeStep={response.data.job.index}>
          {response.data.statuses.map(({ label }: { label: string }) =>
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )}
        </Stepper>
      </Paper>
      <div style={{ flexDirection: "row", display: "flex", justifyContent: "space-around", marginTop: 8 }}>
        <Typography variant='h6'>Invoices/Receivables</Typography>
        <Typography variant='h6'>Job Costs</Typography>
      </div>
      <div style={{ flexDirection: "row", display: "flex" }}>
        <div style={{ width: "100%" }}>
          <Button size="small" onClick={() => setAddInvoiceModalOpen(true)}>
            Add receivable
          </Button>
          <StyledDataGrid
            showColumnRightBorder
            showCellRightBorder
            disableSelectionOnClick
            style={{ height: 'calc(50vh - 220px)' }}
            rows={response?.data?.invoices || []}
            // columns={[...invoiceColumns, actionColumns]}
            columns={invoiceColumns}
            rowsPerPageOptions={[5]}
            sx={{ mr: 1 }}
          />
        </div>
        <div style={{ width: "100%" }}>
          <Button size="small" onClick={() => setMaterialCostsModalOpen(true)}>
            Add Job cost
          </Button>
          <StyledDataGrid
            showColumnRightBorder
            showCellRightBorder
            disableSelectionOnClick
            style={{ height: 'calc(50vh - 220px)' }}
            rows={response?.data?.materialCosts || []}
            // columns={[...materialCostsColumns, actionColumns]}
            columns={materialCostsColumns}
            rowsPerPageOptions={[5]}
            sx={{ ml: 1 }}
          />
        </div>
      </div>
      <Typography sx={{ mt: 2 }} align="center" variant='h6'>Timesheets</Typography>
      <Button size="small" onClick={() => setTimesheetModalOpen(true)}>
        Add time worked
      </Button>
      <StyledDataGrid
        showColumnRightBorder
        showCellRightBorder
        disableSelectionOnClick
        style={{ height: 'calc(50vh - 220px)' }}
        rows={response?.data?.timesheets || []}
        // columns={[...timesheetsColumns, actionColumns]}
        columns={timesheetsColumns}
        rowsPerPageOptions={[5]}
      />
    </>
  );
}
