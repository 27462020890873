import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import useFetch from 'use-http';
import { GridColDef } from '@mui/x-data-grid';
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { Add, Delete } from "@mui/icons-material";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { useState, useEffect, useCallback } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import baseUrl from '../helpers/urls';
import { SelectChangeEvent } from "@mui/material/Select";
import ExportToolbar from "../components/ExportToolbar";
import StyledDataGrid from "../components/StyledDataGrid";

const columns: GridColDef[] = [
  { field: 'first_name', headerName: 'First Name', width: 150, editable: true },
  { field: 'last_name', headerName: 'Last Name', width: 150, editable: true },
  { field: 'email', headerName: 'Email', width: 250, editable: true },
  { field: 'phone_number', headerName: 'Phone', width: 150, editable: true, type: "number", renderCell: (params) => <a href={`tel:${params.value}`}>{params.value}</a> },
];

export default function Employees() {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [employees, setEmployees] = useState<any>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<any>([]);
  const { get, response, loading, error, post } = useFetch(`${baseUrl}/employees`);
  const { del, put } = useFetch(`${baseUrl}/employees`);

  const [isDeleting, setIsDeleting] = useState(false);
  const [totalToDelete, setTotalToDelete] = useState(0);
  const [currentlyDeletingIndex, setCurrentlyDeletingIndex] = useState(0);
  const [editCellSnackbar, setEditCellSnackbar] = useState<any>(null);

  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });

  function updateFormField(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadInitialEmployees() }, []) // componentDidMount

  async function loadInitialEmployees() {
    const initialEmployees = await get();
    if (response.ok) setEmployees(initialEmployees.employees);
  }

  async function createEmployee() {
    const employeeData = await post(
      {
        employee:
        {
          email: formData.email,
          first_name: formData.firstName,
          last_name: formData.lastName,
          phone_number: formData.phoneNumber,
        }
      }
    );
    if (response.ok) {
      setEmployees([...employeeData.employee, ...employees]);
      setAddModalOpen(false);
    }
  }

  async function deleteEmployees() {
    setIsDeleting(true);
    const oldSelectedEmployees = selectedEmployees;
    setCurrentlyDeletingIndex(1);
    setTotalToDelete(oldSelectedEmployees.length);
    let newEmployees = employees;
    oldSelectedEmployees.map(async (employeeId: any, index: number) => {
      const employeeData = await del(`${employeeId}`);
      if (employeeData.deleteCount === 1) {
        newEmployees = newEmployees.filter(({ id }: { id: number }) => id !== employeeId);
        setCurrentlyDeletingIndex(index++);
      }
      if (index === oldSelectedEmployees.length) {
        setIsDeleting(false);
        setEmployees(newEmployees);
        setSelectedEmployees([]);
      }
    });
  }

  const handleCellEditCommit = useCallback(
    async (params) => {
      try {
        // Make the HTTP request to save in the backend
        const { employee } = await put(`${params.id}`, {
          employee: {
            [params.field]: params.value,
          }
        });
        setEditCellSnackbar({ children: 'Employee successfully saved', severity: 'success' });
        setEmployees((prev: any) =>
          prev.map((row: any) => (row.id === params.id ? { ...row, ...employee[0] } : row)),
        );
      } catch (error) {
        setEditCellSnackbar({ children: 'Error while saving user', severity: 'error' });
        // Restore the row in case of error
        setEmployees((prev: any) => [...prev]);
      }
    },
    [put],
  );

  return (
    <>
      {!!editCellSnackbar && (
        <Snackbar open onClose={() => setEditCellSnackbar(false)} autoHideDuration={6000}>
          <Alert {...editCellSnackbar} onClose={() => setEditCellSnackbar(false)} />
        </Snackbar>
      )}
      <Typography variant="h5">Employees</Typography>
      {error && <Typography>Error loading employees</Typography>}
      {loading && <CircularProgress />}
      {isDeleting &&
        <Alert severity="info">
          <AlertTitle>Deleting Employees</AlertTitle>
          {currentlyDeletingIndex} / {totalToDelete}
        </Alert>}
      {!loading &&
        <StyledDataGrid
          showColumnRightBorder
          showCellRightBorder
          disableSelectionOnClick
          onCellEditCommit={handleCellEditCommit}
          checkboxSelection
          style={{ height: 'calc(100vh - 220px)' }}
          rows={employees}
          columns={columns}
          selectionModel={selectedEmployees}
          onSelectionModelChange={(selected) => setSelectedEmployees(selected)}
          components={{
            Toolbar: ExportToolbar,
          }}
        />}
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 32, right: 32 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          onClick={() => setAddModalOpen(true)}
          icon={<Add />}
          tooltipTitle="Add Employee"
        />
        <SpeedDialAction
          onClick={() => deleteEmployees()}
          icon={<Delete />}
          tooltipTitle="Delete Selected Employees"
        />
      </SpeedDial>
      <Dialog open={addModalOpen} onClose={() => setAddModalOpen(false)}>
        <DialogTitle>Add Employee</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            name="email"
            onChange={(e) => updateFormField(e)}
          />
          <TextField
            margin="dense"
            label="First Name"
            fullWidth
            variant="standard"
            name="firstName"
            onChange={(e) => updateFormField(e)}
          />
          <TextField
            margin="dense"
            label="Last Name"
            fullWidth
            variant="standard"
            name="lastName"
            onChange={(e) => updateFormField(e)}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            fullWidth
            variant="standard"
            name="phoneNumber"
            onChange={(e) => updateFormField(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddModalOpen(false)}>Cancel</Button>
          <Button onClick={createEmployee}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
