import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import useFetch from 'use-http';
import useUser from "../hooks/useUser";
import baseUrl from '../helpers/urls';

export default function Register() {
  const { post, response, loading, error } = useFetch(`${baseUrl}/register`)

  const [, setUser] = useUser();

  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  async function register() {
    if (password === password2) {
      const loginData = await post({ user: { username, email, password, first_name: firstName, last_name: lastName } });
      if (response.ok) setUser(loginData.user[0]);
    }
  }

  return (
    <Stack spacing={2}>
      <TextField label="username" value={username} onChange={e => setUsername(e.target.value)} />
      <TextField label="email" value={email} onChange={e => setEmail(e.target.value)} />
      <TextField label="first name" value={firstName} onChange={e => setFirstName(e.target.value)} />
      <TextField label="last name" value={lastName} onChange={e => setLastName(e.target.value)} />
      <TextField label="password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
      <TextField label="confirm password" type="password" value={password2} onChange={e => setPassword2(e.target.value)} />
      <Button variant="contained" onClick={register}>
        Register
      </Button>
      <Button variant="outlined" href="/">
        Back to Login
      </Button>
      {loading && <CircularProgress />}
      {error && <Typography>Error registering</Typography>}
    </Stack>
  );
}
